const cursor = document.querySelector(".cursor");

document.addEventListener("mousemove", (e) => {
  cursor.setAttribute(
    "style",
    "top: " + (e.pageY - 10) + "px; left: " + (e.pageX - 10) + "px;"
  );
});

document.addEventListener("click", () => {
  cursor.classList.add("expand");

  setTimeout(() => {
    cursor.classList.remove("expand");
  }, 500);
});

window.addEventListener("scroll", function () {
  // Get the current scroll position
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  // Get the total height of the page, including content that's not visible
  const pageHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight
  );

  // Get the height of the visible portion of the page (viewport)
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;

  // Calculate how far the user has scrolled from the top of the page
  const scrollDistance = scrollTop + viewportHeight;

  // Check if the user has reached the bottom of the page
  if (scrollDistance >= pageHeight) {
    // Perform any actions you want when reaching the end of the page here
  }
});
